import React from "react";
import ClipLoader  from "react-spinners/ClipLoader";
const Loader = () => {
  return (
    <div className="loader-container">
      <ClipLoader 
        color="#0060ef"
        size={100}
      />
    </div>
  );
};

export default Loader;
