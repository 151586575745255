import React from 'react';

const Navbar = ({ toggleDarkMode, isDarkMode, clearChat, handleLogout }) => {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <h5 className="navbar-brand">Robo App</h5>
        <div className="ms-auto">
        <button
            className="btn btn-outline-light me-2 btn-sm"
            onClick={toggleDarkMode}
            aria-label="Toggle dark mode"
          >
            {isDarkMode ? '☀️' : '🌙'}
          </button>
          <button
            className="btn btn-outline-light me-2 btn-sm"
            onClick={clearChat}
            aria-label="Clear chat history"
          >
            Clear Chat
          </button>
     
          {isAuthenticated && (
            <button
              className="btn btn-outline-light me-2 btn-sm"
              onClick={handleLogout}
              aria-label="Logout"
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
